import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { useBoolean } from '@fluentui/react-hooks';
import { Checkbox } from '@fluentui/react';

//const options: IChoiceGroupOption[] = [
  //{ key: 'A', text: 'Agree' },
  //{ key: 'B', text: 'Option B' },
  //{ key: 'C', text: 'Option C', disabled: true },
//];
const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};
const dialogContentProps = {
  type: DialogType.largeHeader,
  title: 'Toestemming',
  subText: 'Vraag de cliënt of hij/zij akkoord gaat met het gebruiken van dit transcriptie programma. Cliënt dient er van op de hoogte te zijn dat er geen opname wordt gemaakt maar dat audio live vertaald wordt naar tekst.',
  dialogFooter: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export const DialogLargeHeaderExample = ({toggleHideDialog, hideDialog, startSpeechRecognition,setConsentRecording}: any ) => {
  const [selectedKey, setSelectedKey] = React.useState<boolean>(false);
function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
  setSelectedKey(isChecked??false);
}
  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        <Checkbox label="Akkoord, cliënt stemt in met het gebruik" checked={selectedKey} onChange={_onChange} />
       {/* <ChoiceGroup selectedKey={selectedKey} onChange={(e:any)=> setSelectedKey(e?.target.value)} options={options} /> */}
        <DialogFooter>
          <PrimaryButton disabled={!selectedKey} onClick={()=> {setConsentRecording(false); startSpeechRecognition(); toggleHideDialog()} } text="Doorgaan" />
          <DefaultButton onClick={()=> {toggleHideDialog();setSelectedKey(false)}} text="Annuleren" />
        </DialogFooter>
      </Dialog>
    </>
  );
};